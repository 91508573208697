import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { TSystemData } from '../models/system-data';
import * as CryptoJS from 'crypto-js';
import { TAreaData } from '../models/area-data';
import { TNotificationSetting } from '../models/notification-setting';
import { ThemesService } from './themes.service';
import { PgmData } from '../models/pgm';
import { TZoneData } from '../models/zone-data';
import { TEventData } from '../models/event-data';
import { TDeviceUser } from '../models/device-user';
import { EventsService } from './events.service';
import { LoggerService } from '../api/logger.service';
import { HomeConfiguration } from '../models/home-configuration';
import { TSensorData } from '../models/sensor-data';
import { TCamera } from '../models/camera';
import { TThermostatData } from '../models/thermostat-data';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSettings } from 'src/app-settings';
import { SosType, getSystems } from 'src/api/v3/system';
import { defaultHomeConfiguration, UserService } from '../api/user.service';
import { SystemProtegusUserData, UserSystemData, WidgetAreaData, WidgetDataType } from 'src/api/v3/common';
import { PermissionService } from '../api/permission.service';
import { SystemService } from '../api/system/system.service';
import { LocatorService } from './locator.service';
import { PersistenceService } from '../api/persistence.service';
import { TagService } from '../api/tag.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { G16 } from '../configurators/g16/src/app/models/g16-devices';
import { GV } from '../configurators/gv17/src/app/models/gv-devices';
import { gvFamily, trk8Devices } from './global.service';
import { G17 } from '../configurators/g17/src/app/models/g17-devices';
import { AreaState } from 'src/api/v3/system.area';
import { PermissionRole, PermissionRule } from 'src/api/v3/permission';
import { PlatformService } from '../api/platform.service';

@Injectable({
  providedIn: 'root',
})
export class SystemsService implements OnDestroy {
  public systems: TSystemData[] = [];
  public systemBookmarks = [];
  public filteredSystems: TSystemData[] = [];
  public activeSystem: TSystemData = null;
  public activeArea: TAreaData = null;
  private onActiveSystemChangeSource = new Subject<number>();
  public onActiveSystemChange = this.onActiveSystemChangeSource.asObservable();
  private onSystemsLoadedSource = new Subject<void>();
  public onSystemsLoaded = this.onSystemsLoadedSource.asObservable();
  private storeKey = 'a8oiiXKC9Q4VSbOj';
  public addAreaStatusWork = null;
  private tag = 'SystemsService';
  public virtualSystemCount = 0;
  private get permissionsService() { return LocatorService.injector.get(PermissionService); }
  public get activeSystemHelper() { return this.systemHelper(this.activeSystem); };
  private get persistanceService() { return LocatorService.injector.get(PersistenceService); };
  public get systemPageSize() { return this.persistanceService.get('page_limit_systems', 5); }
  public set systemPageSize(value: number) { this.persistanceService.set('page_limit_systems', value); }
  private cleanUpSubscription: Subscription;

  constructor(
    private themes: ThemesService,
    private es: EventsService,
    private us: UserService,
    private l: LoggerService,
    private sanitizer: DomSanitizer,
    private systemService: SystemService,
  ) {
    const authService = LocatorService.injector.get(AuthService);
    this.cleanUpSubscription = authService.onAccountOrRegionChnage.subscribe(() => {
      this.clear();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscription?.unsubscribe();
  }

  private clear() {
    this.systems = [];
    this.activeArea = null;
    this.activeSystem = null;
    this.systemBookmarks = [];
    this.filteredSystems = [];
    this.virtualSystemCount = 0;
  }

  /** Gauna duomenis apie visas vartotojui priklausancias sistema. */
  public loadSystems(nextFragment?: boolean, callback?: any) {
    if ( !this.us.currentUser ) {
      if (callback !== undefined) {
        callback();
      }
      return;
    }
    const that = this;
    let lastSystemName = '';
    if (nextFragment !== undefined && nextFragment && this.systems.length > 0 ) {
      lastSystemName = this.systems[this.systems.length - 1].name;
    }
    getSystems({
      returnSystemCount: true,
      offsetName: lastSystemName ?? '',
    }).subscribe(
      (result) => {
        if (result.success) {
          if (nextFragment === undefined || !nextFragment) {
            that.systems = [];
            that.filteredSystems = [];
          }
          for (const iSystem of result.systems) {
            if (!iSystem || iSystem.hwType === '') {
              continue;
            }
            this.systemService.ingestSystem(iSystem);
            const converted = that.convertSystemFromRaw(iSystem);
            if (that.activeSystem !== null && converted.id === that.activeSystem.id) {
              that.setCurrentSystem(converted);
            } else if (that.activeSystem === null && !that.us.currentUser?.permissions?.permissions.systems.view && !this.us.currentUser?.permissions.permissions.unassigned_devices.view) {
              that.setCurrentSystem(converted);
            } else {
              // that.systems.push(that.getFakeSystem('Fake'));
              // that.systems.push(that.getFakeSystem('Fake 2'));
              that.systems.push(converted);
            }
          }
          if (result.totalSystems !== undefined) {
            this.virtualSystemCount = result.totalSystems;
          }
          if (result.bookmarks !== undefined) {
            that.systemBookmarks = result.bookmarks;
          }
          if (nextFragment === undefined || !nextFragment) {
            if ( this.systems.length === 0 && this.activeSystem ) {
              this.setCurrentSystem(null, false);
            }
            that.onActiveSystemChangeSource.next(this.activeSystem?.id ?? 0);
            that.onSystemsLoadedSource.next();

            const areasForWidget: WidgetAreaData[] = [];
            this.systems.map(s => s.areas.map(a => areasForWidget.push({
              areaAlarmed: a.alarmed,
              areaBoundToPgm: !!s.pgms.find(p => p.area_id === a.id),
              areaId: a.id,
              iconNumber: '0',
              name: a.name,
              noStaySleep: s.noSleepStay,
              pgmEnabled: s.pgms.find(p => p.area_id === a.id)?.enabled ?? false,
              systemId: s.id,
              systemName: s.name,
              useAway: this.systemHelper(s).supports.away(),
              isSosSystem: this.systemHelper(s).can.see.sosButton(),
            })));
            const platform = LocatorService.injector.get(PlatformService);
            if ( platform.isAndroid() ) {
              try {
                // ateity reikes padaryt, kaip su ios
              } catch(e) {}
            } else if ( platform.isApple() ) {
              platform.appleHandler().onWidgetData.postMessage({ type: WidgetDataType.Area, data: areasForWidget });
            }
          }
        } else {
          that.l.log('Nepavyko gauti sistemu duomenu.', that.tag);
        }
        if (callback !== undefined) {
          callback();
        }
      },
      (error) => {
        that.l.log('', that.tag, error);
        if (callback !== undefined) {
          callback();
        }
      }
    );
  }

  public hasSystems(): boolean {
    return this.systems.length > 0;
  }

  public convertSystemFromRaw(systemData: any): TSystemData {
    if (systemData === null) {
      return null;
    }
    if ( !this.us.currentUser ) { return null; }

    const areasFromDb: TAreaData[] = [];
    for (const iArea of systemData.areas) {
      areasFromDb.push({
        name: iArea.name,
        queue_no: iArea.queue_no,
        zones: [],
        id: iArea.id,
        canBypass: iArea.canBypass,
        showKeypad: iArea.showKeypad,
        status: iArea.status,
        statusTime: iArea.status_time === null ? 0 : iArea.status_time,
        alarmed: iArea.alarm_in_memory,
        lastPerson: iArea.last_person_name,
        alarmTime: iArea.alarm_time === null ? 0 : iArea.alarm_time,
        alarmType: iArea.alarm_type,
      });
      if ((iArea.status === AreaState.ArmingInProgress || iArea.status === AreaState.DisarmingInProgress) && iArea.status_time !== null) {
        if (this.addAreaStatusWork !== null) {
          this.addAreaStatusWork(systemData.id, iArea.id, iArea.status_time);
        }
      }
    }
    const notificationsFromDb: TNotificationSetting[] = [];
    let notificationsFromDbEnabled = false;
    let notificationsFromDbSoundsEnabled = false;
    if (systemData.notifications !== null) {
      notificationsFromDbEnabled = systemData.notifications.global_value;
      notificationsFromDbSoundsEnabled = systemData.notifications.sounds_on;
      for (const iNotif of systemData.notifications.notifications) {
        notificationsFromDb.push({
          enabled: iNotif.on,
          id: iNotif.id,
          name: iNotif.name,
          useAlertSound: iNotif.alert_sound_on,
        });
      }
    }
    const pgmsFromDb: PgmData[] = [];
    for (const iPgm of systemData.pgms) {
      if (iPgm.io_ability === 'W' && iPgm.io_type === 3) {
        continue;
      }
      const pgmToAlter: PgmData = {
        id: iPgm.id,
        area_no: 0,
        control_area: iPgm.pgm_as_area_ck === 1,
        enabled: iPgm.enabled === 1,
        icon: iPgm.icon_number,
        name: iPgm.name,
        on: iPgm.on === 1,
        pulseTime: iPgm.pulse_time_in_seconds,
        queue_no: iPgm.queue_no,
        type: iPgm.type,
        area_id: iPgm.area_to_arm,
        pulse_activated_at: iPgm.pulse_start_time === null ? 0 : iPgm.pulse_start_time * 1000,
        io_type: iPgm.io_type,
        io_ability: iPgm.io_ability,
      };
      if (pgmToAlter.control_area) {
        const found = areasFromDb.find((a) => a.id === pgmToAlter.area_id);
        if (found === undefined) {
          pgmToAlter.control_area = false;
          pgmToAlter.area_id = 0;
        } else {
          pgmToAlter.area_no = found.queue_no;
        }
      }
      pgmsFromDb.push(pgmToAlter);
    }

    const zonesFromDb: TZoneData[] = [];
    for (const iZone of systemData.zones) {
      let found = zonesFromDb.find((z) => z.queue_no === iZone.queue_no);
      const areaFound = areasFromDb.find((a) => a.id === iZone.area_id);
      if (areaFound === undefined) {
        continue;
      }
      if (found === undefined) {
        found = {
          id: iZone.id,
          alarmed: false,
          areas: [],
          bypassed: false,
          enabled: true,
          failed: false,
          name: iZone.name,
          native: iZone.native,
          queue_no: iZone.queue_no,
          visible: iZone.visible,
        };
        zonesFromDb.push(found);
      }
      found.areas.push(areaFound.queue_no);
    }

    const eventsFromDb: TEventData[] = [];
    for (const iEvent of systemData.events.events) {
      if (iEvent === null) {
        continue;
      }
      eventsFromDb.push(this.es.convertFromRaw(iEvent));
    }

    const usersFromDb: SystemProtegusUserData[] = [];
    for (const iProtegusUser of systemData.protegus_users) {
      usersFromDb.push({...iProtegusUser});
    }

    const deviceUsersFromDb: TDeviceUser[] = [];
    for (const iDeviceUser of systemData.device_users) {
      if ( iDeviceUser === null ) { continue; }
      deviceUsersFromDb.push(
        new TDeviceUser({
          id: iDeviceUser.id,
          areas: iDeviceUser.areas !== undefined ? iDeviceUser.areas : '',
          code: iDeviceUser.keyboard_code !== undefined ? iDeviceUser.keyboard_code : iDeviceUser.pin !== undefined ? iDeviceUser.pin : '',
          email: iDeviceUser.email,
          enable_data: iDeviceUser.enable_data !== undefined ? iDeviceUser.enable_data : 0,
          name: iDeviceUser.name,
          pgms: iDeviceUser.pgms !== undefined ? iDeviceUser.pgms : -1,
          phone: iDeviceUser.phone,
          present: iDeviceUser.present !== undefined ? iDeviceUser.present : false,
          protegus_user_id: iDeviceUser.protegus_user_id,
          schedule_no: iDeviceUser.schedule_no !== undefined ? iDeviceUser.schedule_no : 0,
          zone_number: iDeviceUser.system_code_no !== undefined ? iDeviceUser.system_code_no : iDeviceUser.user_id !== undefined ? iDeviceUser.user_id : 0,
          isOwner: false,
          ownerPermissions: null,
        })
      );
    }
    deviceUsersFromDb.sort((du1, du2) => du1.zone_number - du2.zone_number);

    const sensorsFromDb: TSensorData[] = [];
    for (const iSensor of systemData.sensors) {
      sensorsFromDb.push({
        active: iSensor.enabled === 1,
        alarmHigh: false,
        alarmLow: false,
        enabled: iSensor.enabled === 1,
        name: iSensor.name,
        queue_no: iSensor.queue_no,
        temperature: '',
        type: iSensor.type,
        notificationAboutHigh: iSensor.alarm_high,
        notificationAboutLow: iSensor.alarm_low,
        highValue: iSensor.high_value,
        lowValue: iSensor.low_value,
      });
    }

    const camerasFromDb: TCamera[] = [];
    for (const iCamera of systemData.cameras) {
      camerasFromDb.push({
        fullUrl: iCamera.full_url,
        protocol: iCamera.protocol,
        host: iCamera.host,
        port: iCamera.port,
        id: iCamera.id,
        name: iCamera.name,
        pass: iCamera.pass,
        path: iCamera.path,
        user: iCamera.user,
        assignedPgms: iCamera.assigned_pgms ?? [],
        assignedZones: iCamera.assigned_zones ?? [],
      });
    }

    const thremostatsFromDb: TThermostatData[] = [];
    for (const iThermostat of systemData.thermostats) {
      thremostatsFromDb.push({
        id: iThermostat.id,
        activeSensor: iThermostat.active_sensor,
        assignedOutput: iThermostat.assigned_output,
        assignedSensors: JSON.parse(iThermostat.assigned_sensors),
        name: iThermostat.name,
        queueNo: iThermostat.queue_no,
        systemId: iThermostat.system_id,
        temperatures: JSON.parse(iThermostat.temperatures),
        action: iThermostat.action,
      });
    }

    let visiblePgms: PgmData[] = null;
    const deviceUser = deviceUsersFromDb.find((u) => u.protegus_user_id === this.us.currentUser.id);
    let userPgms = this.us.currentUser.permissions?.role !== PermissionRole.GeneralUser ? -1 : 0;
    if (systemData.amIMaster) {
      userPgms = -1;
    } else if (deviceUser !== undefined) {
      userPgms = deviceUser.pgms;
    }
    if (userPgms !== null && userPgms > -1) {
      visiblePgms = [];
      for (const iPgm of pgmsFromDb) {
        let value = false;
        /* eslint-disable no-bitwise */
        if ( iPgm.queue_no < 3 ) {
          value = (userPgms & (0x01 << (iPgm.queue_no + 2))) > 0;
        } else if ( iPgm.queue_no > 5 ) {
          value = (userPgms & (0x01 << (iPgm.queue_no - 1))) > 0;
        } else {
          // GV moduliuose 3 4 5 1 2
          value = (userPgms & (0x01 << (iPgm.queue_no - 3))) > 0;
        }
        /* eslint-enable no-bitwise */
        if (value) {
          visiblePgms.push(iPgm);
        }
      }
    }

    const localSystemData: TSystemData = {
      id: systemData.id,
      areas: areasFromDb,
      name: systemData.name,
      pgms: visiblePgms ?? pgmsFromDb,
      online: systemData.online,
      supported_commands: systemData.supported_commands,
      amIMaster: systemData.amIMaster,
      mpass: systemData.mpass,
      address: systemData.address,
      timeZone: systemData.timeZone,
      imei: systemData.imei,
      notificationSoundsEnabled: notificationsFromDbSoundsEnabled,
      notificationsEnabled: notificationsFromDbEnabled,
      notifications: notificationsFromDb,
      sensors: sensorsFromDb,
      signalLevel: systemData.signalLevel,
      zones: zonesFromDb,
      hwType: systemData.hwType,
      canBypassZone: systemData.canBypassZone,
      canUnbypassZone: systemData.canUnbypassZone,
      directControl: systemData.direct,
      events: eventsFromDb,
      noSleepStay: systemData.noSleepStay,
      protegus_users: usersFromDb,
      device_users: deviceUsersFromDb,
      maxDeviceUsers: systemData.maxDeviceUsers,
      panel: systemData.centralPanel,
      coordinates: systemData.coordinates,
      eventConfiguration: JSON.parse(systemData.eventConfiguration),
      canEditUsers: systemData.canEditUsers,
      theme: {
        startColor: systemData.theme.background_start,
        endColor: systemData.theme.background_end,
        fullBackground: systemData.theme.full_background,
      },
      cameras: camerasFromDb,
      thermostats: thremostatsFromDb,
      installerId: systemData.installer_id,
      installerEmail: systemData.installerEmail !== undefined ? systemData.installerEmail : '',
      installerName: systemData.installerName !== undefined ? systemData.installerName : '',
      logo: systemData.logo,
      company_id: systemData.company_id,
      assistedById: systemData.assistedById,
      assistedByEmail: systemData.assistedByEmail,
      supportsFireReset: systemData.supportsFireReset,
      amIWorking: systemData.amIWorking ?? false,
      privacyOfOwners: systemData.privacyOfOwners ?? [],
      owners: systemData.owners ?? [],
      deviceId: systemData.device_id ?? 0,
      created_at: systemData.created_at,
      tags: systemData.tags,
      companyName: systemData.companyName,
      installerAccess: systemData.installerAccess,
      showSosButton: systemData.showSosButton,
      status: systemData.status ?? null,
      fw_version: systemData.fw_version,
      sos_type: systemData.sos_type,
      troubles: systemData.troubles,
    };
    for (const iOwner of localSystemData.owners) {
      const found = deviceUsersFromDb.find((du) => du.protegus_user_id === iOwner.id);
      if (found === undefined) {
        continue;
      }
      const foundPrivacy = localSystemData.privacyOfOwners.find((po) => po.user_id === iOwner.id);
      found.isOwner = true;
      found.ownerPermissions =
        foundPrivacy === undefined
          ? {
              id: 0,
              system_id: localSystemData.id,
              user_id: iOwner.id,
              show_email_admins: true,
              show_name_admins: true,
              show_phone_admins: true,
              show_email_users: true,
              show_name_users: true,
              show_phone_users: true,
            }
          : foundPrivacy;
    }
    if (systemData.logo ) {
      localSystemData.logoDataForStorage = systemData.logo;
    }

    return localSystemData;
  }

  public setCurrentSystemFromRaw(systemData: any) {
    this.l.log('', this.tag, systemData);
    if (!systemData) {
      return;
    }
    this.setCurrentSystem(this.convertSystemFromRaw(systemData));
  }

  public setCurrentSystem(systemData: TSystemData, notify: boolean = true) {
    try {
      if (systemData === null) {
        localStorage.removeItem('last_system_data');
        localStorage.removeItem('last_system');
        this.activeSystem = null;
        this.activeArea = null;
        this.l.log('Išvaloma išsaugota sistema.', this.tag);
        if ( notify ) {
          this.onActiveSystemChangeSource.next();
        }
        return;
      }

      // Sistema ta pati, perkeliam laikinus duomenis (sensorių būsenos, zonų būsenos)
      if (this.activeSystem !== null && this.activeSystem.id === systemData.id) {
        if (systemData.sensors.length > 0 && this.activeSystem.sensors.length > 0) {
          for (const iSensor of systemData.sensors) {
            const foundSensor = this.activeSystem.sensors.find((s) => s.queue_no === iSensor.queue_no);
            if (foundSensor !== undefined) {
              iSensor.alarmHigh = foundSensor.alarmHigh;
              iSensor.alarmLow = foundSensor.alarmLow;
              iSensor.temperature = foundSensor.temperature;
            }
          }
        }
        if (systemData.zones.length > 0 && this.activeSystem.zones.length > 0) {
          for (const iZone of systemData.zones) {
            const foundZone = this.activeSystem.zones.find((z) => z.queue_no === iZone.queue_no);
            if (foundZone !== undefined) {
              iZone.alarmed = foundZone.alarmed;
              iZone.bypassed = foundZone.bypassed;
              iZone.failed = foundZone.failed;
            }
          }
        }
        if (systemData.pgms.length > 0 && this.activeSystem.pgms.length > 0) {
          for (const pgm of systemData.pgms) {
            const foundPgm = this.activeSystem.pgms.find((p) => p.queue_no === pgm.queue_no);
            if (foundPgm !== undefined) {
              pgm.area_id = foundPgm.area_id;
              pgm.area_no = foundPgm.area_no;
              pgm.control_area = foundPgm.control_area;
            }
          }
        }
      }
      this.systemService.syncChanges(systemData.id, systemData);
      this.systemService.systems.set(systemData.id, systemData);
      const data = CryptoJS.AES.encrypt(JSON.stringify(systemData), this.storeKey).toString();
      localStorage.setItem('last_system_data', data);
      localStorage.setItem('last_system', systemData.id.toString());
      this.activeSystem = systemData;
      if (this.activeArea !== null) {
        this.setCurrentArea(this.activeArea.id);
      }
      this.themes.setHomeTheme(systemData.theme);
      const found = this.systems.find((fs) => fs.imei === this.activeSystem.imei);
      if (found !== undefined) {
        this.systems = this.systems.filter((sf) => sf.imei !== found.imei);
      }
      this.systems.push(this.activeSystem);
      this.systems.sort((s1, s2) => s1.name.localeCompare(s2.name));
      this.l.log('Išsaugoti aktyvios sistemos duomenys', this.tag, this.activeSystem);
    } catch (e) {
      this.l.log('', this.tag, e);
    }
    if ( notify ) {
      this.onActiveSystemChangeSource.next();
    }
  }

  public getLastSystemData(): TSystemData | null {
    try {
      const systemData = localStorage.getItem('last_system_data');
      if (systemData === null) {
        return null;
      }
      const bytes = CryptoJS.AES.decrypt(systemData, this.storeKey);
      if (bytes.toString()) {
        const systemObject = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (systemObject.logoDataForStorage !== undefined && systemObject.logoDataForStorage !== '') {
          systemObject.logo = systemObject.logoDataForStorage;
        }
        if ( systemObject.sos_type === undefined ) {
          systemObject.sos_type = SosType.Basic;
        }
        if ( systemObject.troubles === undefined ) {
          systemObject.troubles = null;
        }
        return systemObject;
      }
    } catch (e) {
      this.l.log('', this.tag, e);
    }
    return null;
  }

  public getSystem(systemId: number) {
    return this.systems.find((s) => s.id === systemId);
  }

  public removeSystem(systemId: number) {
    this.systems = this.systems.filter((s) => s.id !== systemId);
    const systemService = LocatorService.injector.get(SystemService);
    if ( systemService.systems.has(systemId) ) {
      systemService.systems.delete(systemId);
    }
    this.filteredSystems = this.filteredSystems.filter((s) => s.id !== systemId);
    if (this.virtualSystemCount > 0) {
      this.virtualSystemCount--;
    }
    if (this.activeSystem !== null && this.activeSystem.id === systemId) {
      this.setCurrentSystemFromInternalList(true);
    }
  }

  public setCurrentSystemFromInternalList(takeFirst: boolean) {
    if (this.systems.length === 0) {
      this.setCurrentSystem(null);
      return;
    }

    if (takeFirst) {
      this.setCurrentSystem(this.systems[0]);
    } else {
      this.setCurrentSystem(this.systems[this.systems.length - 1]);
    }
  }

  public addSystem(system: TSystemData) {
    const found = this.systems.find((s) => s.id === system.id);
    if (found !== undefined) {
      return;
    }
    this.systems.push(system);
    if (this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin) {
      this.virtualSystemCount++;
    }
    if ( this.activeSystem === null && !this.us.currentUser.permissions?.permissions.systems.view ) {
      this.setCurrentSystemFromInternalList(true);
    }
  }

  public setCurrentArea(areaId: number) {
    if (areaId === 0) {
      this.activeArea = null;
      return;
    }

    const area = this.activeSystem.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      this.activeArea = null;
      return;
    }

    this.activeArea = area;
  }

  /**
   * Į local storage išsaugo aktyvios sistemos duomenis.
   * Dažniausiai naudojama po to, kai pakeičiami kažkokie duomenys.
   *
   * @param systemId Nebūtinas. Nurodo patikrinti ar aktyvios sistemos ID toks pats. Tada saugo duomenis.
   */
  public saveActiveSystem(systemId?: number) {
    if (this.activeSystem === null) {
      return;
    }
    if (systemId !== undefined && this.activeSystem.id !== systemId) {
      return;
    }
    this.setCurrentSystem(this.activeSystem);
  }

  public getDefaultHomeConfigurationForActiveSystem(): HomeConfiguration {
    const def = Object.assign({}, defaultHomeConfiguration);
    if (this.activeSystem === null) {
      return def;
    }
    def.system_id = this.activeSystem.id;

    return def;
  }

  public updateOrAddSystem(systemData: TSystemData) {
    const systemsFound: TSystemData[] = [];
    let foundSystem = this.filteredSystems.find((sf) => sf.id === systemData.id);
    if (foundSystem !== undefined) {
      systemsFound.push(foundSystem);
    }
    foundSystem = this.systems.find((s) => s.id === systemData.id);
    if (foundSystem !== undefined) {
      systemsFound.push(foundSystem);
    }
    if (foundSystem === undefined) {
      this.addSystem(systemData);
      return;
    }

    for (const found of systemsFound) {
      found.name = systemData.name;
      found.pgms = systemData.pgms;
      found.areas = systemData.areas;
      found.online = systemData.online;
      found.supported_commands = systemData.supported_commands;
      found.amIMaster = systemData.amIMaster;
      found.mpass = systemData.mpass;
      found.address = systemData.address;
      found.timeZone = systemData.timeZone;
      found.notificationsEnabled = systemData.notificationsEnabled;
      found.notificationSoundsEnabled = systemData.notificationSoundsEnabled;
      found.notifications = systemData.notifications;
      found.sensors = systemData.sensors;
      found.signalLevel = systemData.signalLevel;
      found.zones = systemData.zones;
      found.canBypassZone = systemData.canBypassZone;
      found.canUnbypassZone = systemData.canUnbypassZone;
      found.directControl = systemData.directControl;
      found.events = systemData.events;
      found.noSleepStay = systemData.noSleepStay;
      found.protegus_users = systemData.protegus_users;
      found.device_users = systemData.device_users;
      found.maxDeviceUsers = systemData.maxDeviceUsers;
      found.panel = systemData.panel;
      found.coordinates = systemData.coordinates;
      found.eventConfiguration = systemData.eventConfiguration;
      found.canEditUsers = systemData.canEditUsers;
      found.theme = systemData.theme;
      found.cameras = systemData.cameras;
      found.thermostats = systemData.thermostats;
      found.installerId = systemData.installerId;
      found.installerName = systemData.installerName;
      found.installerEmail = systemData.installerEmail;
      found.logo = systemData.logo;
      found.company_id = systemData.company_id;
      found.assistedById = systemData.assistedById;
      found.assistedByEmail = systemData.assistedByEmail;
      found.supportsFireReset = systemData.supportsFireReset;
      found.amIWorking = systemData.amIWorking;
      found.privacyOfOwners = systemData.privacyOfOwners;
      found.owners = systemData.owners;
      found.deviceId = systemData.deviceId;
      found.logoDataForStorage = systemData.logoDataForStorage;
      found.activeGroupIcon = systemData.activeGroupIcon;
      found.activeGroupId = systemData.activeGroupId;
      found.created_at = systemData.created_at;
      found.tags = systemData.tags;
      found.companyName = systemData.companyName;
      found.installerAccess = systemData.installerAccess;
      found.showSosButton = systemData.showSosButton;
      found.status = systemData.status;
      found.fw_version = systemData.fw_version;
      found.sos_type = systemData.sos_type;
      found.troubles = systemData.troubles;
    }

    this.saveActiveSystem(systemData.id);
  }

  private getFakeSystem(systemName: string) {
    let generatedImei = '';
    const values = '0123456789';
    for (let i = 0; i < 15; i++) {
      const randomDigit = Math.floor(Math.random() * values.length);
      generatedImei += values[randomDigit];
    }
    return {
      id: Math.floor(Math.random() * 100),
      areas: [],
      name: systemName,
      pgms: [],
      online: false,
      supported_commands: '',
      amIMaster: true,
      mpass: '',
      address: 'Addr',
      timeZone: 'UTC',
      imei: generatedImei,
      notificationSoundsEnabled: true,
      notificationsEnabled: true,
      notifications: [],
      sensors: [],
      signalLevel: 9,
      zones: [],
      hwType: 'SP3',
      canBypassZone: true,
      canUnbypassZone: true,
      directControl: true,
      events: [],
      noSleepStay: false,
      protegus_users: [],
      device_users: [],
      maxDeviceUsers: 999,
      panel: 0,
      coordinates: '',
      eventConfiguration: null,
      canEditUsers: true,
      theme: {
        startColor: '#123456',
        endColor: '#654321',
        fullBackground: '',
      },
      cameras: [],
      thermostats: [],
      installerId: 0,
      installerEmail: '',
      installerName: '',
      logo: null,
      company_id: this.us.currentUser.id,
      assistedById: undefined,
      assistedByEmail: undefined,
      supportsFireReset: false,
      amIWorking: false,
      privacyOfOwners: [],
      owners: [],
    };
  }

  public getSystemCount(): number {
    if (this.us.currentUser.permissions?.permissions.systems.view) {
      return this.virtualSystemCount;
    }

    return this.systems.length;
  }

  public updateAccessData(newData) {
    const system = this.getSystem(newData.system_id);
    if (system === undefined) {
      return;
    }
    const user = system.device_users.find((du) => du.protegus_user_id === newData.protegus_user_id);
    if (user === undefined) {
      return;
    }
    user.present = newData.present;
    if (user.protegus_user_id === this.us.currentUser.id) {
      system.amIWorking = newData.present;
    }
  }

  /**
   * Gražina permission pagal aktyvią sistemą.
   */
  public get currentUserPermissions(): PermissionRule | undefined {
    if ( !this.us.currentUser ) { return; }
    if ( !this.activeSystem ) { return this.us.currentUser.permissions; }

    let systemUsers: UserSystemData[] = this.us.systemUsers.get(this.activeSystem.id);
    if ( !systemUsers && this.activeSystem.protegus_users ) {
      systemUsers = this.activeSystem.protegus_users.map((u) => ({
        id: u.id,
        master: u.master,
        personal_permissions: u.personal_permissions,
        system_permissions_id: u.system_permissions_id,
      }));
    }
    const found = systemUsers?.find(u => u.id === this.us.currentUser.id);
    if ( !found ) { return this.us.currentUser.permissions; }
    if ( !found.personal_permissions ) {
      return this.permissionsService.getPermission(found.system_permissions_id);
    }

    return {
      company_id: this.us.currentUser.company_id,
      default: false,
      description: '',
      id: 0,
      name: '',
      parent_id: null,
      permissions: found.personal_permissions,
      role: found.master ? PermissionRole.SystemMaster : PermissionRole.SystemUser,
      assigned_users: 0,
      tags: [],
      companyName: this.us.currentUser.belongsToCompany?.name ?? '',
    };
  }

  public systemHelper(system: TSystemData) {
    return {
      supports: {
        areas: () => {
          if (!system) { return false; }
          if (system.supported_commands.indexOf('.9.') !== -1) {
            return true;
          }
          return system.hwType === 'FC' || (!system.directControl && system.hwType !== 'G17F' && !gvFamily.includes(system.deviceId));
        },
        events: () => {
          if (!system) { return false; }
          return true;
        },
        sleepState: () => {
          // return true; // naudojam kai reikia testuoti
          if (!system) { return false; }
          return system.directControl && !system.noSleepStay;
        },
        stayState: () => {
          // return true; // naudojam kai reikia testuoti
          if (!system) { return false; }
          return system.directControl && !system.noSleepStay;
        },
        gvTypeUsers: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.11.') !== -1;
        },
        cgTypeUsers: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.14.') !== -1;
        },
        simpleUsers: () => {
          if (!system) { return false; }
          return system.hwType !== 'CG17';
        },
        sensors: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.8.') !== -1;
        },
        outputs: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.3.') !== -1;
        },
        away: () => {
          if (!system) { return false; }
          return system.panel >= 0x10 && system.panel < 0x20; // DSC centralems.
        },
        areaControlByPgm: () => {
          if (!system) { return false; }
          return !system.directControl;
        },
        silenceSiren: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.23.') !== -1;
        },
        cancelAlarm: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.23.') !== -1;
        },
        sensorNotifications: () => {
          if (!system) { return false; }
          return system.hwType === 'SP3' || system.hwType === 'SP4' || system.hwType === 'FC';
        },
        thermostats: () => {
          if (!system) { return false; }
          return system.hwType === 'SP3' || system.hwType === 'SP4' || system.hwType === 'FC' || system.hwType === 'CG17';
        },
        advancedSettings: () => {
          if ( localStorage.getItem('ref') === 'dev_setup' || localStorage.getItem('ref') === 'device-information' ) { return true; }
          if (!system) { return false; }
          // toks pats sarasas turi buti advanced-settings komponente.
          // 0x00 tik del to, kad DB gali buti senas irasas be hwid duomenu, todel naujus duomenis gaus per info komanda
          return [0x00, G17.CG17, G17.G17F, 0x3b, 0x55, GV.GV17, GV.WP17, G16.E16_3A, G16.E16T_3C, G16.G16_42, G16.G16_43, G16.G16_44, G16.G16T_45, G16.G16T_46, G16.G16T_47, G16.G16T_48, G16.GET, G16.G16T_50, 0x51, G16.GT_PLUS, G16.GT].includes(system.deviceId);
        },
        workMode: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.1E.') !== -1;
        },
        zones: () => {
          if (!system) { return false; }
          return true;
        },
        wirelessDeviceLearning: () => {
          if (!system) { return false; }
          return (system.hwType === 'SP3' || system.hwType === 'SP4') && system.supported_commands.indexOf('.25.') !== -1;
        },
        deviceTransfer: () =>
          system && (
            system.supported_commands.indexOf('.18.') !== -1 ||
            system.supported_commands.endsWith('.18') ||
            system.supported_commands.indexOf('.22.') !== -1 ||
            system.supported_commands.endsWith('.22')
          ) && !trk8Devices.includes(system.deviceId),
        zoneBypass: () => {
          if ( !this.systemHelper(system).supports.zones() ) { return false; }
          if ( !system.canBypassZone ) { return false; }
          return system.hwType !== 'G16T' && system.hwType !== 'E16T' && system.hwType !== 'FC';
        },
        sos: () => system && !gvFamily.includes(system.deviceId) && system.areas.length > 0, // pagal idėją, palaikomi visi, kas dirba su sritimis
        deviceStatus: () => system && trk8Devices.includes(system.deviceId) && system.status?.operator,
        gPanelUsers: () => {
          if (!system) { return false; }
          return system.supported_commands.indexOf('.26.') !== -1;
        }
      },
      can: {
        see: {
          information: () => {
            if (!system) { return false; }
            return this.currentUserPermissions?.permissions.sys_information.view;
          },
          outputs: () => {
            if ( !this.systemHelper(system).supports.outputs() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_outputs.view;
          },
          zones: () =>
            this.systemHelper(system).supports.zones()
            && (this.currentUserPermissions?.permissions.sys_zones.view),
          area: (areaQueueNumber: number) => {
            if ( !this.systemHelper(system).can.see.areas() ) { return false; }
            const user = system.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
            if (user === undefined) {
              const foundProtegusUser = system.protegus_users.find(pu => pu.id === this.us.currentUser.id);
              if ( foundProtegusUser ) {
                return foundProtegusUser.master;
              } else {
                return TagService.hasMatchingTags(true, this.us.currentUser.user_tags, system.tags);
              }
            }
            if (user.areas === '') {
              return true;
            }
            if (areaQueueNumber > user.areas.length) {
              return false;
            }
            return user.areas.charAt(areaQueueNumber - 1) === '1';
          },
          areas: () => {
            if ( !this.systemHelper(system).supports.areas() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_areas.view;
          },
          events: () => {
            if (!system) { return false; }
            return this.systemHelper(system).supports.events() && this.currentUserPermissions?.permissions.sys_events.view;
          },
          users: () => system && ( this.currentUserPermissions?.permissions.sys_users.view ),
          sensors: () => {
            if ( !this.systemHelper(system).supports.sensors() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_sensors.view;
          },
          cameras: () => {
            if (!system) { return false; }
            return this.currentUserPermissions?.permissions.sys_cameras.view;
          },
          thermostats: () => {
            if ( !this.systemHelper(system).supports.thermostats() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_thermostats.view;
          },
          notificationSettings: () => system && this.currentUserPermissions?.permissions.sys_notifications.view,
          advancedSettings: () => this.systemHelper(system).supports.advancedSettings() && this.currentUserPermissions?.permissions.sys_advanced_settings.view,
          sosButton: () => this.systemHelper(system).supports.sos() && this.currentUserPermissions?.permissions.panic_settings.execute && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active) && system.showSosButton,
        },
        edit: {
          information: () =>
            this.systemHelper(system).can.see.information()
            && this.currentUserPermissions?.permissions.sys_information.edit
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          outputs: () =>
            this.systemHelper(system).supports.outputs()
            && (this.currentUserPermissions?.permissions.sys_outputs.edit || this.systemHelper(system).can.add.outputs() )
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          zones: () => {
            if ( !this.systemHelper(system).supports.zones() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_zones.edit && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
          },
          areas: () =>
            this.systemHelper(system).can.see.areas()
            && this.currentUserPermissions?.permissions.sys_areas.edit
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          users: () =>
            system
            && (this.currentUserPermissions?.permissions.sys_users.edit || this.systemHelper(system).can.add.users())
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          userKeyboardCode: () =>
            !!system
            && ((system.directControl && this.systemHelper(system).supports.cgTypeUsers())
            || this.systemHelper(system).supports.gvTypeUsers()
            || (system.directControl && this.systemHelper(system).supports.gPanelUsers())),
          sensors: () => {
            if ( !this.systemHelper(system).supports.sensors() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_sensors.edit && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
          },
          cameras: () => {
            if (!system) { return false; }
            return (this.currentUserPermissions?.permissions.sys_cameras.edit || this.systemHelper(system).can.add.cameras())
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
          },
          thermostats: () => {
            if ( !this.systemHelper(system).can.see.thermostats() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_thermostats.edit && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
          },
          notificationSettings: () =>
            this.systemHelper(system).can.see.notificationSettings()
            && this.currentUserPermissions?.permissions.sys_notifications.edit
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          advancedSettings: () =>
            this.systemHelper(system).supports.advancedSettings()
            && this.currentUserPermissions?.permissions.sys_advanced_settings.edit
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
        },
        control: {
          area: (areaQueueNumber: number) => {
            if (this.systemHelper(system).supports.gvTypeUsers()) {
              return false;
            }
            if ( !this.systemHelper(system).can.control.areas() ) { return false; }
            const user = system.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
            if (user === undefined) {
              return true;
            }
            if (user.areas === '') {
              return false;
            }
            if (areaQueueNumber > user.areas.length) {
              return false;
            }
            return user.areas.charAt(areaQueueNumber - 1) === '1';
          },
          areas: () => {
            if (!system) { return false; }
            if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active) {
              return false;
            }
            return this.currentUserPermissions?.permissions.sys_areas.execute;
          },
          thermostats: () => {
            if ( !this.systemHelper(system).can.see.thermostats() ) { return false; }
            return this.currentUserPermissions?.permissions.sys_thermostats.execute && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
          },
        },
        delete: {
          system: () => {
            if ( !system ) { return false; }
            if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active) {
              return false;
            }
            if ( this.currentUserPermissions?.permissions.systems.delete ) { return true; }
            if ( !this.currentUserPermissions?.permissions.system.delete ) { return false; }
            const usersBySystem = this.us.systemUsers.get(system.id);
            if ( !usersBySystem ) { return false; }
            if ( usersBySystem.find(u => u.id === this.us.currentUser?.id) ) { return true; }
            return false;
          },
          areas: () => {
            if (!system) { return false; }
            if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active) {
              return false;
            }
            return this.currentUserPermissions?.permissions.sys_areas.delete && !system.directControl;
          },
          zones: () => {
            if ( !this.systemHelper(system).supports.zones() ) { return false; }
            if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active) {
              return false;
            }
            return system.hwType !== 'SP3' && system.hwType !== 'SP4' && system.hwType !== 'G17F' && system.hwType !== 'FC' && !gvFamily.includes(system.deviceId) && this.currentUserPermissions?.permissions.sys_zones.delete;
          },
          outputs: () =>
            this.systemHelper(system).supports.outputs()
            && ( gvFamily.includes(system.deviceId) )
            && this.currentUserPermissions?.permissions.sys_outputs.delete
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          users: () => system && this.currentUserPermissions?.permissions.sys_users.delete && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          cameras: () => system && this.currentUserPermissions?.permissions.sys_cameras.delete && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
        },
        add: {
          areas: () =>
            this.systemHelper(system).supports.areas()
            && this.currentUserPermissions?.permissions.sys_areas.create
            && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          zones: () => {
            if (! this.systemHelper(system).supports.zones() ) { return false; }
            if (system.hwType === 'G17F' || gvFamily.includes(system.deviceId) ) {
              return false;
            }
            if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active) {
              return false;
            }
            if ( !this.currentUserPermissions?.permissions.sys_zones.create ) { return false; }
            if ( !system.directControl ) {
              return true;
            }
            // toliau dirbam su direct control sistemomis.
            const invisibleZones = system.zones.filter((z) => z.enabled && !z.visible && z.native);
            return invisibleZones.length > 0;
          },
          users: () => system && this.currentUserPermissions?.permissions.sys_users.create && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
          outputs: () => {
            if (!this.systemHelper(system).supports.outputs()) { return false; }
            if ( !gvFamily.includes(system.deviceId) ) {
              return false;
            }
            return system.supported_commands.indexOf('.19.') !== -1
            && this.currentUserPermissions?.permissions.sys_outputs.create
                && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
          },
          cameras: () => system && this.currentUserPermissions?.permissions.sys_cameras.create && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
        },
        transferOwnership: () =>
          system
          && this.currentUserPermissions?.permissions.sys_transfer.execute
          && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
        transferDevice: () =>
          this.systemHelper(system).supports.deviceTransfer()
          && this.currentUserPermissions?.permissions.sys_device_transfer.execute
          && this.us.currentUser.settings.togglable.find(t => t.field === 'user_can_transfer_device')?.value
          && AppSettings.deviceTransferToAppEnabled
          && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
        transferToCompany: () =>
          system
          && this.currentUserPermissions?.permissions.sys_transfer_to_company.execute
          && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active)
          && (!this.us.currentUser.settings.togglable.find(t => t.field === 'can_transfer_to_company_by_email')?.value ?? true),
        bypassZone: () => {
          if ( !this.systemHelper(system).supports.zones() ) { return false; }
          if ( !system.canBypassZone ) { return false; }
          return system.hwType !== 'G16T'
          && system.hwType !== 'E16T'
          && system.hwType !== 'FC'
          && this.currentUserPermissions?.permissions.sys_zones.execute
          && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
        },
        unbypassZone: () => {
          if (!system) { return false; }
          return system.canUnbypassZone && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
        },
        resetFireSensors: () => {
          if (!this.systemHelper(system).supports.sensors()) { return false; }
          return system.supportsFireReset
          && this.currentUserPermissions?.permissions.sys_reset_sensors.execute
          && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
        },
        synchronize: () =>
          system
          && this.currentUserPermissions?.permissions.sys_synchronisation.execute
          && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active),
        requestSos: () => this.systemHelper(system).can.see.sosButton(),
      },
      hasEnabledOutputs: () => {
        if (!system) { return false; }
        const found = system.pgms.find((o) => o.enabled);
        return found !== undefined;
      },
    };
  };
}
